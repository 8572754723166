import * as React from "react"

import Seo from "../components/seo"
import Features from "../components/features"
import Services from "../components/services"
import Products from "../components/products"

const IndexPage = () => (
  <>
    <Seo title="ConecTimme" />
    <Features />
    <Services />
    <Products />
  </>
)

export default IndexPage
