import * as React from "react"
import styled from "styled-components"
import { CheckSquare } from "@styled-icons/bootstrap/CheckSquare"

const Container = styled.section`
  padding-top: 120px;
  padding-bottom: 120px;

  h2 {
    /* font-size: 3rem; */
    text-align: center;
    margin-bottom: 50px;
  }

  ul {
    list-style: none;

    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }

  ul li {
    font-size: 1.275rem;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: start;
    column-gap: 20px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }
`

const Products = () => (
  <Container id="produtos">
    <h2>Materiais</h2>
    <div>
      <ul>
        <li>
          <div>
            <CheckSquare size={22} />
          </div>
          Quadros de comando
        </li>
        <li>
          <div>
            <CheckSquare size={22} />
          </div>
          Servos motores
        </li>
        <li>
          <div>
            <CheckSquare size={22} />
          </div>
          Inversores
        </li>
        <li>
          <div>
            <CheckSquare size={22} />
          </div>
          Clp's
        </li>
      </ul>
      <ul>
        <li>
          <div>
            <CheckSquare size={22} />
          </div>
          Válvulas pneumática
        </li>
        <li>
          <div>
            <CheckSquare size={22} />
          </div>
          Válvulas hidráulicas
        </li>
        <li>
          <div>
            <CheckSquare size={22} />
          </div>
          Componentes completos para automação industrial
        </li>
      </ul>
    </div>
  </Container>
)

export default Products
