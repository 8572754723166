import * as React from "react"
import styled from "styled-components"
import { ElectricalServices } from "@styled-icons/material/ElectricalServices"
import { MiscellaneousServices } from "@styled-icons/material/MiscellaneousServices"
import { Engineering } from "@styled-icons/material/Engineering"
import { ModelTraining } from "@styled-icons/material-outlined/ModelTraining"
import { Row, Col } from "react-bootstrap"

const Container = styled.section`
  padding-top: 120px;
  padding-bottom: 120px;

  h1 {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 50px;
  }

  .items {
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 20px;
    margin: 5px;
    text-align: center;
    border-radius: 10px;
    height: 100%;

    h4 {
      font-family: "Karla", sans-serif;
      font-weight: 700;
      font-size: 1.25rem;
    }
  }

  .col-12 {
    margin-bottom: 16px;
  }

  @media (min-width: 576px) {
    .items {
      box-shadow: 0px 4px 20px rgb(225 225 225 / 24%);
    }
  }
`

const StyledIcon = styled.div`
  padding: 15px;
  height: 100px;
  width: 100px;
  background-color: ${({ bgColor }) =>
    bgColor ? bgColor : "rgba(12, 89, 219, 0.3)"};
  border-radius: 50px;
  margin: 0 auto 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: #ffffff;
  }
`

const Features = () => (
  <Container id="servicos">
    <h1>Serviços</h1>

    <Row>
      <Col sm="6" xs="12">
        <div className="items">
          <StyledIcon>
            <Engineering size={40} />
          </StyledIcon>
          <h4>Automação Industrial</h4>
          <p>Desenvolvemos o projeto ideal que atende a sua necessidade.</p>
        </div>
      </Col>

      <Col sm="6" xs="12">
        <div className="items">
          <StyledIcon bgColor="rgba(142, 1, 254, 0.3)">
            <ModelTraining size={40} />
          </StyledIcon>
          <h4>Retrofit</h4>
          <p>
            Priorizando a modernização e a atualização eficaz para sua empresa.
          </p>
        </div>
      </Col>
    </Row>

    <Row>
      <Col sm="6" xs="12">
        <div className="items">
          <StyledIcon bgColor="rgba(255, 254, 51, 0.3)">
            <ElectricalServices size={40} />
          </StyledIcon>
          <h4>Comandos elétricos</h4>
          <p>Quadros de acionamento simples e complexos.</p>
        </div>
      </Col>

      <Col sm="6" xs="12">
        <div className="items">
          <StyledIcon bgColor="rgba(254, 51, 3, 0.3)">
            <MiscellaneousServices size={40} />
          </StyledIcon>
          <h4>Manutenção Industrial</h4>
          <p>Diagnósticos precisos e mão de obra especializada.</p>
        </div>
      </Col>
    </Row>
  </Container>
)

export default Features
